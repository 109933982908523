export class Parser {
}
export class InputParser extends Parser {
    constructor(regex, formatName, toParse) {
        super();
        const matches = toParse.match(regex);
        const extracted = matches ? matches[1] : '';
        if (toParse.length > 8 && extracted.length === 8) {
            this.parseInfo = {
                value: formatName + ' truncated to "' + extracted + '"',
                severity: 'info',
            };
        }
        else if (extracted.length < toParse.length) {
            this.parseInfo = {
                value: 'Invalid ' + formatName + ' input',
                severity: 'info',
            };
        }
        else {
            this.parseInfo = null;
        }
        this.extracted = extracted;
    }
    get() {
        return this.extracted;
    }
    getParseInfo() {
        return this.parseInfo;
    }
}
export class BinInputParser extends InputParser {
    constructor(toParse) {
        super(/^(?:0x)?([0-1]{0,32})/, 'binary', toParse);
    }
}
export class HexInputParser extends InputParser {
    constructor(toParse) {
        super(/^(?:0x)?([0-9a-fA-F]{0,8})/, 'hex', toParse);
    }
}
