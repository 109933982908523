import instructions from '../../data/instructionSpec.json';
import registers from '../../data/registers.json';
const rInstructions = instructions.filter(i => i.functionCode !== null);
export function getOpcodeValue(bits) {
    var _a;
    const opcode = parseInt(bits, 2);
    if (opcode === 0) {
        // R-type instruction
        return 'R';
    }
    else {
        const instruction = instructions.find(i => i.opcode === opcode);
        return (_a = instruction === null || instruction === void 0 ? void 0 : instruction.mnemonic) !== null && _a !== void 0 ? _a : 'unknown';
    }
}
export function getShiftAmount(shiftAmount) {
    return parseInt(shiftAmount, 2).toString(10);
}
export function getFunctionCode(functionCode) {
    var _a, _b;
    return (_b = (_a = rInstructions.find((i) => i.functionCode === parseInt(functionCode, 2))) === null || _a === void 0 ? void 0 : _a.mnemonic) !== null && _b !== void 0 ? _b : 'unknown';
}
function getSignedImmediate(binary) {
    const firstBit = binary[0];
    if (firstBit === '0') {
        return getUnsignedImmediate(binary);
    }
    else {
        const flippedBits = binary.split('').map(b => b === '0' ? '1' : '0').join('');
        const complement = parseInt(flippedBits, 2) + 1;
        return -complement;
    }
}
function getUnsignedImmediate(binary) {
    return parseInt(binary, 2);
}
export function getImmediate(signed, immediateFormat) {
    const parseImmediate = signed ? getSignedImmediate : getUnsignedImmediate;
    return (immediate) => immediateFormat.format(parseImmediate(immediate));
}
export function getJumpAddress(immediateFormat) {
    return (address) => immediateFormat.format(parseInt(address, 2));
}
export function getUnknown(bits) {
    return 'unknown';
}
export function getRegisterNumber(binary) {
    const registerNumber = parseInt(binary, 2);
    // eslint-disable-next-line no-useless-escape
    return `\$${registerNumber}`;
}
export function getRegisterName(binary) {
    var _a, _b;
    const registerNumber = parseInt(binary, 2);
    const registerName = (_b = (_a = registers.find(r => r.number === registerNumber)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'unknown';
    // eslint-disable-next-line no-useless-escape
    return `\$${registerName}`;
}
export function getRegisterNumberFromName(name) {
    var _a, _b;
    const nameAsNumber = parseInt(name);
    if (isNaN(nameAsNumber)) {
        return (_b = (_a = registers.find(r => r.name === name)) === null || _a === void 0 ? void 0 : _a.number) !== null && _b !== void 0 ? _b : null;
    }
    else {
        return nameAsNumber;
    }
}
export function getInstructionSpecWithMnemonic(mnemonic) {
    var _a;
    return (_a = instructions.find(i => i.mnemonic === mnemonic)) !== null && _a !== void 0 ? _a : null;
}
