import FieldExtractor from './field-extractor';
import { OpcodeField, UnknownField } from './fields';
import Instruction from './instruction';
export default class UnknownInstruction extends Instruction {
    constructor(opcode, unknown) {
        super(opcode, [opcode, unknown], // fields
        null, ['instruction', 'unknown']);
        this.unknown = unknown;
    }
    static fromBinary(binary, settings) {
        const extractor = new FieldExtractor(binary, settings);
        const opcode = extractor.extractField(OpcodeField);
        const unknown = extractor.extractField(UnknownField);
        return new UnknownInstruction(opcode, unknown);
    }
    toMips() {
        return null;
    }
}
