export class ImmediateFormat {
    constructor(name) {
        this.name = name;
    }
}
export class HexFormat extends ImmediateFormat {
    constructor() {
        super('hex');
    }
    format(value) {
        if (value >= 0) {
            return this.formatUnsigned(value);
        }
        else {
            return '-' + this.formatUnsigned(-value);
        }
    }
    formatUnsigned(value) {
        return '0x' + value.toString(16);
    }
}
export class DecFormat extends ImmediateFormat {
    constructor() {
        super('decimal');
    }
    format(value) {
        return value.toString(10);
    }
}
export class BinFormat extends ImmediateFormat {
    constructor() {
        super('binary');
    }
    format(value) {
        if (value >= 0) {
            return this.formatUnsigned(value);
        }
        else {
            return '-' + this.formatUnsigned(-value);
        }
    }
    formatUnsigned(value) {
        return '0b' + value.toString(2);
    }
}
