import instructionSpecs from '../data/instructionSpec.json';
import FieldExtractor from './field-extractor';
import Instruction, { isUnsignedImmediateInstruction } from './instruction';
import { OpcodeField, RsField, RtField, UnsignedImmediateField, SignedImmediateField } from './fields';
function formatIInstruction(mnemonic, fields) {
    // Fields = [rs, rt, immed]
    const fieldValues = fields.map(f => f.value);
    switch (mnemonic) {
        case 'lbu':
        case 'lhu':
        case 'll':
        case 'lui':
        case 'lw':
        case 'lb':
        case 'sb':
        case 'sc':
        case 'sh':
        case 'sw':
            return [
                {
                    value: fieldValues[1],
                    fieldRole: mnemonic.startsWith('s') ? 'source' : 'destination',
                },
                {
                    value: ', ',
                    fieldRole: null,
                },
                {
                    value: fieldValues[2],
                    fieldRole: 'immediate',
                },
                {
                    value: '(',
                    fieldRole: null,
                },
                {
                    value: fieldValues[0],
                    fieldRole: mnemonic.startsWith('s') ? 'destination' : 'source',
                },
                {
                    value: ')',
                    fieldRole: null,
                },
            ];
        case 'beq':
        case 'bne':
        default:
            // format: addi r1, r2, immed
            return [
                {
                    value: fieldValues[1],
                    fieldRole: 'source1',
                },
                {
                    value: ', ',
                    fieldRole: null,
                },
                {
                    value: fieldValues[0],
                    fieldRole: 'source2',
                },
                {
                    value: ', ',
                    fieldRole: null,
                },
                {
                    value: fieldValues[2],
                    fieldRole: 'immediate'
                }
            ];
    }
}
export default class IInstruction extends Instruction {
    constructor(opcode, rs, rt, immediate, fields, instructionSpec, fieldRoles) {
        super(opcode, fields, instructionSpec, fieldRoles);
        this.rs = rs;
        this.rt = rt;
        this.immediate = immediate;
    }
    static fromBinary(binary, settings) {
        var _a;
        const extractor = new FieldExtractor(binary, settings);
        const opcode = extractor.extractField(OpcodeField);
        const instructionSpec = (_a = instructionSpecs.find(spec => spec.opcode === opcode.interpolatedValue)) !== null && _a !== void 0 ? _a : null;
        const signed = instructionSpec ? !isUnsignedImmediateInstruction(instructionSpec) : false;
        const rs = extractor.extractField(RsField);
        const rt = extractor.extractField(RtField);
        const immediate = extractor.extractField(signed ? SignedImmediateField : UnsignedImmediateField);
        const fields = [opcode, rs, rt, immediate];
        function isFieldRole(fieldRole) {
            return fieldRole !== null;
        }
        const fieldRoles = instructionSpec ? formatIInstruction(instructionSpec.mnemonic, fields)
            .map(f => f.fieldRole)
            .filter(isFieldRole) : null;
        return new IInstruction(opcode, rs, rt, immediate, fields, instructionSpec, fieldRoles
            ? ['instruction', ...fieldRoles]
            : ['instruction', 'source', 'destination', 'immediate']);
    }
    toMips() {
        var _a, _b;
        if (!((_a = this.spec) === null || _a === void 0 ? void 0 : _a.mnemonic)) {
            return null;
        }
        const fieldsInInstruction = ['rs', 'rt', 'immed'];
        const filteredFields = this.fields
            .filter(f => fieldsInInstruction.includes(f.name))
            .sort((f1, f2) => fieldsInInstruction.indexOf(f1.name) - fieldsInInstruction.indexOf(f2.name));
        const argumentMipsParts = formatIInstruction((_b = this.spec) === null || _b === void 0 ? void 0 : _b.mnemonic, filteredFields);
        return [
            {
                value: this.spec.mnemonic,
                fieldRole: 'instruction',
            },
            {
                value: ' ',
                fieldRole: null,
            },
            ...argumentMipsParts,
        ];
    }
}
