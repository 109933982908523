export default class FieldExtractor {
    constructor(binary, settings) {
        this.bits = binary;
        this.settings = settings;
    }
    extractBits(length) {
        const extracted = this.bits.substring(0, length);
        this.bits = this.bits.substring(length);
        return extracted;
    }
    extractField(fieldType) {
        const extractedBits = this.extractBits(fieldType.fieldLength);
        return new fieldType(extractedBits, this.settings);
    }
}
