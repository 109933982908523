export class InstructionField {
    constructor(name, binary, length, getValue) {
        this.name = name;
        this.binary = binary;
        this.length = length;
        const interpolatedBinary = binary.padEnd(length, '0');
        try {
            this.value = getValue(interpolatedBinary);
        }
        catch (e) {
            this.value = 'unknown';
        }
        this.interpolatedValue = parseInt(interpolatedBinary, 2);
    }
}
