import { getImmediate } from './extractors';
import { InstructionField } from './instruction-field';
export class ImmediateField extends InstructionField {
    constructor(binary, signed, settings) {
        super('immed', binary, 16, getImmediate(signed, settings.immediateFormat));
    }
}
ImmediateField.fieldLength = 16;
export class UnsignedImmediateField extends ImmediateField {
    constructor(binary, settings) {
        super(binary, false, settings);
    }
}
export class SignedImmediateField extends ImmediateField {
    constructor(binary, settings) {
        super(binary, true, settings);
    }
}
