import instructionSpecs from '../data/instructionSpec.json';
import FieldExtractor from './field-extractor';
import { JumpAddressField, OpcodeField } from './fields';
import Instruction from './instruction';
export default class JInstruction extends Instruction {
    constructor(opcode, jumpAddress, instructionSpec) {
        super(opcode, [opcode, jumpAddress], // fields
        instructionSpec, ['instruction', 'jump address']);
        this.jumpAddress = jumpAddress;
    }
    static fromBinary(binary, settings) {
        var _a;
        const extractor = new FieldExtractor(binary, settings);
        const opcode = extractor.extractField(OpcodeField);
        const jumpAddress = extractor.extractField(JumpAddressField);
        const instructionSpec = (_a = instructionSpecs.find(spec => spec.opcode === opcode.interpolatedValue)) !== null && _a !== void 0 ? _a : null;
        return new JInstruction(opcode, jumpAddress, instructionSpec);
    }
    toMips() {
        return [
            {
                value: this.opcode.value,
                fieldRole: 'instruction',
            },
            {
                value: ' ',
                fieldRole: null,
            },
            {
                value: this.jumpAddress.value,
                fieldRole: 'jump address',
            },
        ];
    }
}
