export function hexToBin(hex) {
    if (!hex)
        return '';
    const newLength = hex.length * 4;
    return parseInt(hex, 16).toString(2).padStart(newLength, '0');
}
export function binToHex(bin) {
    var _a;
    if (!bin)
        return '';
    const newLength = Math.ceil(bin.length / 4) * 4;
    const paddedBin = bin.padEnd(newLength, '0');
    return ((_a = paddedBin
        .match(/.{1,4}/g)) !== null && _a !== void 0 ? _a : [])
        .map((bits) => parseInt(bits, 2).toString(16))
        .join('');
}
export function getZeroPadding(binary) {
    return '0'.repeat(Math.max(0, 32 - binary.length));
}
export function formatBinary(binary) {
    var _a, _b;
    return (_b = (_a = binary.match(/.{1,4}/g)) === null || _a === void 0 ? void 0 : _a.join(' ')) !== null && _b !== void 0 ? _b : '';
}
export function formatBinaryEnd(binary) {
    const chunkCount = Math.floor(binary.length / 4);
    const partialChunkSize = binary.length % 4;
    const partialChunk = '0'.repeat(partialChunkSize);
    return partialChunk + ' 0000'.repeat(chunkCount);
}
