import instructionSpecs from '../data/instructionSpec.json';
import FieldExtractor from './field-extractor';
import { RsField, RtField, RdField, ShiftAmountField, OpcodeField, FunctionCodeField } from './fields';
import Instruction from './instruction';
function getRelevantFields(spec) {
    // all
    // rs (jr)
    // shift (rd, rt, shamt) (sll, srl, sra)
    // rs and rt (div/ divu mult multu)
    // rd only (mfhi, mflo)
    // rd and rs (mfc0)
    switch (spec === null || spec === void 0 ? void 0 : spec.mnemonic) {
        case 'jr':
            return ['opcode', 'rs', 'funct'];
        case 'sll':
        case 'srl':
        case 'sra':
            return ['opcode', 'rd', 'rt', 'shamt', 'funct'];
        case 'div':
        case 'divu':
        case 'mult':
        case 'multu':
            return ['opcode', 'rs', 'rt', 'funct'];
        case 'mfhi':
        case 'mflo':
            return ['opcode', 'rd', 'funct'];
        // case 'mfc0':
        // The opcode is 10 in hex
        // return ['rd', 'rs', 'fcode'];
        default:
            return ['opcode', 'rd', 'rs', 'rt', 'funct'];
    }
}
function getFieldRoles(spec) {
    // all
    // rs (jr)
    // shift (rd, rt, shamt) (sll, srl, sra)
    // rs and rt (div/ divu mult multu)
    // rd only (mfhi, mflo)
    // rd and rs (mfc0)
    switch (spec === null || spec === void 0 ? void 0 : spec.mnemonic) {
        case 'jr':
            return ['instruction', 'source', 'unused', 'unused', 'unused', 'instruction'];
        case 'sll':
        case 'srl':
        case 'sra':
            return ['instruction', 'unused', 'source', 'destination', 'shift amount', 'instruction'];
        case 'div':
        case 'divu':
        case 'mult':
        case 'multu':
            return ['instruction', 'source1', 'source2', 'unused', 'unused', 'instruction'];
        case 'mfhi':
        case 'mflo':
            return ['instruction', 'unused', 'unused', 'destination', 'unused', 'instruction'];
        // case 'mfc0':
        // The opcode is 10 in hex
        // return ['rd', 'rs', 'fcode'];
        default:
            return ['instruction', 'destination', 'source1', 'source2', 'shift amount', 'instruction'];
    }
}
export default class RInstruction extends Instruction {
    constructor(opcode, rs, rt, rd, shamt, funct, instructionSpec, fieldRoles) {
        super(opcode, [opcode, rs, rt, rd, shamt, funct], // fields
        instructionSpec, fieldRoles);
        this.rs = rs;
        this.rt = rt;
        this.rd = rd;
        this.shamt = shamt;
        this.funct = funct;
    }
    static fromBinary(binary, settings) {
        var _a;
        const extractor = new FieldExtractor(binary, settings);
        const opcode = extractor.extractField(OpcodeField);
        const rs = extractor.extractField(RsField);
        const rt = extractor.extractField(RtField);
        const rd = extractor.extractField(RdField);
        const shamt = extractor.extractField(ShiftAmountField);
        const funct = extractor.extractField(FunctionCodeField);
        const instructionSpec = (_a = instructionSpecs.find(spec => spec.functionCode === funct.interpolatedValue)) !== null && _a !== void 0 ? _a : null;
        const fieldRoles = getFieldRoles(instructionSpec);
        return new RInstruction(opcode, rs, rt, rd, shamt, funct, instructionSpec, fieldRoles);
    }
    toMips() {
        if (!this.spec)
            return null;
        const usedFieldNames = getRelevantFields(this.spec);
        const fieldsInInstruction = ['rd', 'rs', 'rt', 'shamt'];
        const registerFields = this.fields
            .map((f, i) => {
            return {
                name: f.name,
                value: f.value,
                fieldRole: this.fieldRoles[i],
            };
        })
            .filter(f => fieldsInInstruction.includes(f.name) && usedFieldNames.includes(f.name))
            .sort((f1, f2) => fieldsInInstruction.indexOf(f1.name) - fieldsInInstruction.indexOf(f2.name));
        const registerMipParts = registerFields.flatMap(field => [
            {
                value: field.value,
                fieldRole: field.fieldRole,
            },
            {
                value: ', ',
                fieldRole: null,
            }
        ]);
        // Remove extra comma
        registerMipParts.pop();
        return [
            {
                value: this.spec.mnemonic,
                fieldRole: 'instruction',
            },
            {
                value: ' ',
                fieldRole: null,
            },
            ...registerMipParts,
        ];
    }
}
