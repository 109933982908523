import RInstruction from '../r-instruction';
import IInstruction from '../i-instruction';
import JInstruction from '../j-instruction';
import instructionSpecs from '../../data/instructionSpec.json';
import UnknownInstruction from '../unknown-instruction';
import { Parser } from './input-parser';
import { MipsInstructionFormat } from '../mips-formats/mips-instruction-format';
function getType(binary) {
    if (binary.length < 6) {
        return 'U';
    }
    const opcode = parseInt(binary.substring(0, 6), 2);
    if (opcode === 0) {
        // R-type instruction
        return 'R';
    }
    else {
        const instruction = instructionSpecs.find(i => i.opcode === opcode);
        return instruction === null || instruction === void 0 ? void 0 : instruction.type;
    }
}
/**
 * Parses a MIPS instruction and returns an Instruction.
 */
export class MipsDecoder extends Parser {
    constructor(binary, settings) {
        super();
        this.instruction = MipsDecoder.decode(binary, settings);
    }
    static decode(binary, settings) {
        const type = getType(binary);
        switch (type) {
            case 'R': {
                return RInstruction.fromBinary(binary, settings);
            }
            case 'I': {
                return IInstruction.fromBinary(binary, settings);
            }
            case 'J': {
                return JInstruction.fromBinary(binary, settings);
            }
            case 'U': {
                return UnknownInstruction.fromBinary(binary, settings);
            }
        }
    }
    get() {
        return this.instruction;
    }
    getParseInfo() {
        return null;
    }
}
// TODO: Make this return an Instruction to be more consistent.
/**
 * Parses a MIPS instruction and returns its binary form.
 */
export class MipsEncoder extends Parser {
    constructor(mipsString) {
        var _a;
        super();
        // this.encodedInstruction = this.getMipsInstructionBinary(mipsInstruction);
        // Try to map instruction to every format
        const results = MipsInstructionFormat.FORMATS
            .map(format => format.parseMips(mipsString));
        const instructionResult = (_a = results
            .find(result => result.value !== null)) !== null && _a !== void 0 ? _a : null;
        const message = results.map(f => { var _a, _b; return (_b = (_a = f.message) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''; }).join('');
        if (instructionResult !== null) {
            // Found instruction
            this.result = instructionResult;
        }
        else {
            // No instruction found
            this.result = {
                value: null,
                message: {
                    value: message,
                    severity: 'error',
                }
            };
        }
    }
    get() {
        return this.result.value;
    }
    getParseInfo() {
        return this.result.message;
    }
}
