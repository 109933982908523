const shiftMnemonics = ['sll', 'srl', 'sra'];
const loadStoreMnemonics = ['lbu', 'lhu', 'll', 'lui', 'lw', 'lb', 'sb', 'sc', 'sh', 'sw'];
/**
 * Returns if the instruction in MIPS is declared in the form "mne $r1, $r2, $r3"
 */
export function isInstructionDeclaredAsR(instructionSpec) {
    return instructionSpec.type === 'R' && !isShiftInstruction(instructionSpec);
}
/**
 * Returns if the instruction in MIPS is declared in the form "mne $r1, $r2, immed"
 */
export function isInstructionDeclaredAsI(instructionSpec) {
    return (instructionSpec.type === 'I' && !isLoadStoreInstruction(instructionSpec))
        || isShiftInstruction(instructionSpec);
}
export function isShiftInstruction(instructionSpec) {
    return shiftMnemonics.includes(instructionSpec.mnemonic);
}
export function isLoadStoreInstruction(instructionSpec) {
    return loadStoreMnemonics.includes(instructionSpec.mnemonic);
}
export function isJumpInstruction(instructionSpec) {
    return instructionSpec.type === 'J';
}
export function isUnsignedImmediateInstruction(instructionSpec) {
    return instructionSpec.mnemonic.endsWith('u');
}
export default class Instruction {
    constructor(opcode, fields, spec, fieldRoles) {
        this.opcode = opcode;
        this.fields = fields;
        this.spec = spec;
        this.fieldRoles = fieldRoles;
    }
}
