import { InstructionField } from './instruction-field';
import { getRegisterName, getRegisterNumber } from './extractors';
export class RegisterField extends InstructionField {
    constructor(registerName, binary, settings) {
        super(registerName, binary, 5, settings.registerMode === 'names' ? getRegisterName : getRegisterNumber);
    }
}
export class RsField extends RegisterField {
    constructor(binary, settings) {
        super('rs', binary, settings);
    }
}
RsField.fieldLength = 5;
export class RtField extends RegisterField {
    constructor(binary, settings) {
        super('rt', binary, settings);
    }
}
RtField.fieldLength = 5;
export class RdField extends RegisterField {
    constructor(binary, settings) {
        super('rd', binary, settings);
    }
}
RdField.fieldLength = 5;
